import * as React from "react"
import MobileDrawer from "./mobile-drawer"

const Header = () => {
  return (
    <div className="w-full p-6">

      <header className="w-full mx-auto">
        <nav className="flex items-center justify-end mx-auto">
          <div>
            <MobileDrawer />
          </div>
        </nav>
      </header>
    </div>
  )
}

export default Header