import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'

const MobileDrawer = () => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <div className="flex justify-end">
        <button
          className="text-black focus:outline-none focus:ring-2 focus:ring-white bg-transparent hover:bg-transparent p-0"
          onClick={() => setOpen(true)}
        >
          <span className="sr-only">Open panel</span>
          <MenuIcon className="h-10 w-10 text-black" aria-hidden="true" />
        </button>
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" static className="fixed inset-0 overflow-hidden z-40" open={open} onClose={setOpen}>
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="relative w-screen max-w-md">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                      <button
                        className="text-white focus:outline-none focus:ring-2 focus:ring-white bg-transparent hover:bg-transparent p-0"
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-full flex flex-col py-6 bg-gray-200 shadow-xl overflow-y-scroll">
                    <nav className="flex flex-col mt-4 px-4">
                      <div className="mt-2 mb-6">
                        <p className='px-4 mb-1'>Do you want to see your website here?</p>
                        <a
                          href='https://form.jotform.com/220480633133042'
                          target="_blank"
                          rel="noopener noreferrer"
                          className="px-4 py-2 text-base text-left underline"
                        >
                          Submit Your Gatsby Site
                        </a>
                      </div>

                      <div className="mt-2 mb-6">
                        <p className='px-4 mb-1'>We'd love to hear your suggestions</p>
                        <a
                          href='https://form.jotform.com/220481206794456'
                          target="_blank"
                          rel="noopener noreferrer"
                          className="px-4 py-2 text-base text-left underline"
                        >
                          Send Your Feedback
                        </a>
                      </div>

                      <div className="mt-2 mb-6">
                        <p className='px-4 mb-1'>Get in touch</p>
                        <a
                          href='https://form.jotform.com/220481010549447'
                          target="_blank"
                          rel="noopener noreferrer"
                          className="px-4 py-2 text-base text-left underline"
                        >
                          Contact Us
                        </a>
                      </div>

                      <div className="mt-6">
                        <h2 className='px-4 py-2'>GatsbySites.com is built with Gatsby v4.</h2>
                      </div>
                    </nav>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default MobileDrawer