import React from "react"

const Widget = () => (
  <div className="fixed bottom-0 right-0 mb-2 mr-2 lg:mb-3 lg:mr-3 z-50">
    <a
      href='https://www.fastweb.dev/'
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="bg-black py-2 px-3">
        <button className="text-white text-lg"><span className="text-sm">By</span> <span className="font-bold">fastweb</span>.dev</button>
      </div>
    </a>
  </div>
)

export default Widget